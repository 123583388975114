import React, { Fragment } from 'react';
import BerlandDetail from './Projects/BerlandDetail';
import TodaysIposDetail from './Projects/TodaysIposDetail';
import LandingDetail from './Projects/Interactive3D';
import ThreadvestDetail from './Projects/ThreadvestDetail';
import MenderDetail from './Projects/MenderDetail';
import ComputerVisionDetail from './Projects/ComputerVisionDetail';
import Container from 'react-bootstrap/Container';
import { Color, Frame } from 'framer';
import { landingPageExample } from './Projects/LandingPage';
import { motion } from 'framer-motion';

export function Projects() {
  const [showInteractiveDetail, setShowInteractiveDetail] = React.useState(false);
  const [showBerlandDetail, setShowBerlandDetail] = React.useState(false);
  const [showTodaysIposDetail, setShowTodaysIposDetail] = React.useState(false);
  const [showThreadvestDetail, setShowThreadvestDetail] = React.useState(false);
  const [showMenderDetail, setShowMenderDetail] = React.useState(false);
  const [showComputerVisionDetail, setShowComputerVisionDetail] = React.useState(false);

  const blend1 = Color.interpolate(Color('#FCF33F'), Color('#93FE33'));
  const blend2 = Color.interpolate(Color('#FF8F52'), Color('#FFB945'));
  const blend3 = Color.interpolate(Color('#B32795'), Color('#FF6BDF'));
  const blend4 = Color.interpolate(Color('#2757E8'), Color('#2B97FF'));
  const blend5 = Color.interpolate(Color('#FC264D'), Color('#E617A4'));
  const blend6 = Color.interpolate(Color('#89FF6B'), Color('#EFFF5E'));

  const projectObjects = [
    {
      title: 'Interactive 3D',
      onTapSetStateFunc: () => {},
      onClick: landingPageExample,
      colorA: blend1(0),
      colorB: blend1(1),
    },
    {
      title: "Today's IPOs",
      onTapSetStateFunc: setShowTodaysIposDetail,
      onClick: () => {},
      colorA: blend2(0),
      colorB: blend2(1),
    },
    {
      title: 'Dr. Berland',
      onTapSetStateFunc: setShowBerlandDetail,
      onClick: () => {},
      colorA: blend3(0),
      colorB: blend3(1),
    },
    {
      title: 'Threadvest',
      onTapSetStateFunc: setShowThreadvestDetail,
      onClick: () => {},
      colorA: blend4(0),
      colorB: blend4(1),
    },
    {
      title: 'Mender',
      onTapSetStateFunc: setShowMenderDetail,
      onClick: () => {},
      colorA: blend5(1),
      colorB: blend5(0),
    },
    {
      title: 'Computer Vision',
      onTapSetStateFunc: setShowComputerVisionDetail,
      onClick: () => {},
      colorA: blend6(0),
      colorB: blend6(1),
    },
  ];

  const projectListVariants = {
    open: {
      transition: { staggerChildren: 0.1, delayChildren: 0.3 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  };

  const projectItemVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
    closed: {
      y: 500,
      opacity: 0,
    },
  };

  const generateProjectButtons = (projectObject) => {
    return (
      <motion.li key={projectObject.title} variants={projectItemVariants}>
        <Frame
          whileHover={{
            scale: 1.15,
            transition: { type: 'tween', ease: 'linear', duration: 0.1 },
          }}
          whileTap={{ scale: 0.9, transition: { duration: 0.1 } }}
          style={{
            position: 'relative',
            color: 'black',
            fontSize: 20,
            fontWeight: 400,
            fontStyle: 'normal',
            fontFamily: 'serenity, sans-seriff',
            padding: 5,
            boxShadow: '2px 2px 3px lightGrey',
            marginTop: 15,
            marginBottom: 15,
          }}
          onTap={projectObject.onTapSetStateFunc}
          onClick={() => projectObject.onClick(setShowInteractiveDetail)}
          size='100%'
          radius={20}
          background={projectObject.colorA}
          animate={{
            background: [projectObject.colorB, projectObject.colorA, projectObject.colorB],
            transition: {
              type: 'tween',
              ease: 'linear',
              loop: 'Infinity',
              duration: 4,
            },
          }}>
          <Container
            style={{
              borderRadius: 30,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              justifyContent: 'center',
            }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'center',
                marginRight: 50,
                marginLeft: 50,
              }}>
              {projectObject.title}
            </div>
          </Container>
        </Frame>
      </motion.li>
    );
  };

  return (
    <Fragment>
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingRight: 50,
          marginTop: 160,
        }}>
        <motion.ul
          initial='closed'
          animate='open'
          variants={projectListVariants}
          style={{
            listStyleType: 'none',
          }}>
          {projectObjects.map((project) => generateProjectButtons(project))}
        </motion.ul>
      </Container>
      <LandingDetail show={showInteractiveDetail} onHide={() => setShowInteractiveDetail(false)} />

      <BerlandDetail show={showBerlandDetail} onHide={() => setShowBerlandDetail(false)} />
      <TodaysIposDetail show={showTodaysIposDetail} onHide={() => setShowTodaysIposDetail(false)} />
      <ThreadvestDetail show={showThreadvestDetail} onHide={() => setShowThreadvestDetail(false)} />
      <MenderDetail show={showMenderDetail} onHide={() => setShowMenderDetail(false)} />
      <ComputerVisionDetail show={showComputerVisionDetail} onHide={() => setShowComputerVisionDetail(false)} />
    </Fragment>
  );
}
