import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';

export default function ThreadvestDetail(props) {
  return (
    <Modal {...props} size='xl' centered>
      <Modal.Header
        style={{
          border: '1px solid #575757',
          backgroundColor: 'black',
          color: 'white',
        }}
        closeButton>
        <Container style={{ paddingLeft: 50 }}>
          <Row style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            <Modal.Title
              as={'h1'}
              style={{
                fontFamily: 'co-headline, sans-serif',
                fontWeight: 700,
                fontStyle: 'normal',
                fontSize: 54,
              }}>
              App Age
            </Modal.Title>

            <Modal.Title
              style={{
                fontFamily: 'ff-kava-web-pro, sans-serif;',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: 24,
                marginTop: 6,
                marginLeft: 20,
                marginRight: 20,
              }}>
              <a
                href='https://mender.app'
                target='_blank'
                rel='noreferrer noopener'
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}>
                Mender
              </a>
            </Modal.Title>

            <Modal.Title
              as={'p'}
              style={{
                fontFamily: 'co-text, sans-serif',
                fontWeight: 300,
                fontStyle: 'normal',
                fontSize: 12,
                marginTop: 3,
              }}>
              Launching Fall 2020
            </Modal.Title>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body style={{ border: '1px solid #575757' }}>
        <Container
          style={{
            fontSize: 16,
            padding: 0,
            margin: 0,
            justifyContent: 'center',
          }}>
          <Col>
            <Row style={{ justifyContent: 'center', marginBottom: 25 }}>
              <Image src={'images/Mender-Screens-3up.jpg'} fluid />
            </Row>
            <Row
              style={{
                justifyContent: 'center',
                marginBottom: 12,
              }}>
              <h4>Challenge</h4>
            </Row>
            <Row style={{ justifyContent: 'center', marginBottom: 20 }}>
              <p>Produce a one of a kind tool to help busy families run efficiently.</p>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                marginBottom: 12,
              }}>
              <h4>Solution</h4>
            </Row>
            <Row style={{ marginBottom: 20, justifyContent: 'center' }}>
              <Col xs={12} md={10}>
                <p>
                  Design a cross-platform mobile app where family members can access their private family data from
                  anywhere. The app acts as a control panel that tracks daily family activities and responsibilities. It
                  stores pertinent family info and enables efficient communications so families can focus on the more
                  important aspects of family life.
                </p>
              </Col>
            </Row>
            <Row style={{ justifyContent: 'center', marginBottom: 12 }}>
              <h4>Features</h4>
            </Row>
            <Row style={{ justifyContent: 'center', marginBottom: 20 }}>
              <ul>
                <li>IOS & Android mobile app</li>
                <li>Custom radial navigation design</li>
                <li>Share lists, polls, chats, todos with entire family</li>
                <li>Store pertinent info for individual family members</li>
                <li>Feature rich with plans to add even more</li>
              </ul>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: '1px solid #575757',
          justifyContent: 'center',
          backgroundColor: 'black',
          color: 'white',
        }}>
        <Button variant='outline-secondary' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
