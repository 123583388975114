import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
// import Carousel from "react-bootstrap/Carousel";

export default function ThreadvestDetail(props) {
  // const windowAspect = window.innerWidth / window.innerHeight;

  return (
    <Modal {...props} size="xl" centered closeButton>
      <Modal.Header
        style={{
          border: "1px solid #575757",
          backgroundColor: "black",
          color: "white"
        }}
        closeButton
      >
        <Container style={{ paddingLeft: 50 }}>
          <Row style={{ justifyContent: "space-around", alignItems: "center" }}>
            <Modal.Title
              as={"h1"}
              style={{
                fontFamily: "co-headline, sans-serif",
                fontWeight: 700,
                fontStyle: "normal",
                fontSize: 54
              }}
            >
              App Age
            </Modal.Title>

            <Modal.Title
              style={{
                fontFamily: "ff-kava-web-pro, sans-serif;",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: 24,
                marginTop: 6,
                marginLeft: 20,
                marginRight: 20
              }}
            >
              <a
                href="https://threadvest.com"
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
              >
                Threadvest
              </a>
            </Modal.Title>

            <Modal.Title
              as={"p"}
              style={{
                fontFamily: "co-text, sans-serif",
                fontWeight: 300,
                fontStyle: "normal",
                fontSize: 12,
                marginTop: 3
              }}
            >
              Launched September 2019
            </Modal.Title>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body style={{ border: "1px solid #575757" }}>
        <Container
          style={{
            fontSize: 16,
            padding: 0,
            margin: 0,
            justifyContent: "center"
          }}
        >
          <Col>
            <Row style={{ justifyContent: "center", marginBottom: 25 }}></Row>
            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Challenge</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <p>
                Provide backend engineering and algorithm design for FinTech
                company in the ETF industry.
              </p>
            </Row>

            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Solution</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <ul>
                <li>Extract, transfer, load (ETL) pipeline from data provider APIs to MySQL</li>
                <li>Statistical modeling using Numpy and Pandas</li>
                <li>Implementation of natural language processing model</li>
                <li>Creation and management of production and dev environments on Azure Functions (serverless)</li>
                <li>OAuth 2.0 user authentication application using Okta</li>
              </ul>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "1px solid #575757",
          justifyContent: "center",
          backgroundColor: "black",
          color: "white"
        }}
      >
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
