import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";

export default function ComputerVisionDetail(props) {
  return (
    <Modal {...props} size="xl" centered>
      <Modal.Header
        style={{
          border: "1px solid #575757",
          backgroundColor: "black",
          color: "white"
        }}
        closeButton
      >
        <Container style={{ paddingLeft: 50 }}>
          <Row style={{ justifyContent: "space-around", alignItems: "center" }}>
            <Modal.Title
              as={"h1"}
              style={{
                fontFamily: "co-headline, sans-serif",
                fontWeight: 700,
                fontStyle: "normal",
                fontSize: 54
              }}
            >
              App Age
            </Modal.Title>

            <Modal.Title
              style={{
                fontFamily: "ff-kava-web-pro, sans-serif;",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: 24,
                marginTop: 6,
                marginLeft: 20,
                marginRight: 20
              }}
            >
              <a
                href="https://www.appagetech.com"
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
              >
                Computer Vision
              </a>
            </Modal.Title>

            <Modal.Title
              as={"p"}
              style={{
                fontFamily: "co-text, sans-serif",
                fontWeight: 300,
                fontStyle: "normal",
                fontSize: 12,
                marginTop: 3
              }}
            >
              Launching 2020
            </Modal.Title>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body style={{ border: "1px solid #575757" }}>
        <Container
          style={{
            fontSize: 16,
            padding: 0,
            margin: 0,
            justifyContent: "center"
          }}
        >
          <Col>
            <Row style={{ justifyContent: "center", marginBottom: 25 }}></Row>
            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Challenge</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <p>
                Build top-secret tool using computer vision and artificial
                intelligence.
              </p>
            </Row>

            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Solution</h4>
            </Row>
            <Row style={{ marginBottom: 20, justifyContent: "center" }}>
              <p>Details coming soon!</p>
            </Row>
            {/* <Row style={{ justifyContent: "center", marginBottom: 12 }}>
              <h4>Features</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <ul>
                <li>Dashboard creation</li>
                <li>API connectivity with data management and process scheduling</li>
                <li>Logo design, typography, and color selection</li>
                <li>CI / CD pipelines</li>
                <li>Highly available web app (multi-node, load balancing)</li>
              </ul>
            </Row> */}
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "1px solid #575757",
          justifyContent: "center",
          backgroundColor: "black",
          color: "white"
        }}
      >
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
