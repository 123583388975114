import React, { Component, Fragment } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

class Navigation extends Component {
  render() {
    const appAge = {
      fontFamily: `CoHeadline-Bold, Co Headline, sans-serif`,
      color: "#000000",
      fontSize: 54,
      paddingRight: 20,
      paddingLeft: 20
    };

    const linkStyle = {
      fontFamily: `"CoHeadline-Bold", "Co Headline", sans-serif`,
      color: "#000000",
      fontSize: 36,
      paddingLeft: 60,
      paddingRight: 60,
      textDecoration: "none"
    };

    return (
      <Fragment>
        <Navbar
          style={{
            background: "white",
            position: "fixed",
            top: 0,
            height: "auto",
            width: "100%",
            zIndex: 1
          }}
          expand="lg"
          collapseOnSelect
        >
          <Container>
            <Link to="/">
              <Navbar.Brand style={appAge}>
                <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                  App Age
                </motion.div>
              </Navbar.Brand>
            </Link>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse
              id="responsive-navbar-nav"
              style={{ backgroundColor: "white" }}
            >
              <Nav style={{ alignItems: "center" }}>
                <Link  style={linkStyle} to="/about">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    about
                  </motion.div>
                </Link>
                <Link style={linkStyle} to="/contact">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    contact
                  </motion.div>
                </Link>
                <Link style={linkStyle} to="/projects">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                  >
                    projects
                  </motion.div>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Fragment>
    );
  }
}

export default Navigation;
