import React from "react";
import Modal from "react-bootstrap/Modal";

export default class Calendly extends React.Component {

    componentDidMount() {
      const head = document.querySelector('head');
      this.script = document.createElement('script');
      this.script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
      head.appendChild(this.script);
    }
  
    componentWillUnmount() {
        // cleanup the widgets code
        const head = document.querySelector('head');
        head.removeChild(this.script);
    }
  
    render() {
        return (
            <div 
                className="calendly-inline-widget"
                data-url="https://calendly.com/appagetech/30min"
                style={{ minWidth: '320px', height: '670px' }} />
        )
    }
};
