import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

const windowAspect = window.innerWidth / window.innerHeight;
const variableMarginTop = windowAspect > 1 ? -300 : -350;

class Client extends Component {
  render() {
    return (
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingRight: 50,
          marginTop: 160,
        }}>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>User ID</Form.Label>
            <Form.Control type="email" placeholder="Enter User ID" />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group>
            <Button variant="dark" type="submit">
              Login
            </Button>
          </Form.Group>
        </Form>
      </Container>
    );
  }
}

export default Client;
