import React, { Component, Fragment } from "react";
import ContactForm from "./ContactForm";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Figure from "react-bootstrap/Figure";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitterSquare } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import Modal from "react-bootstrap/Modal";
import Calendly from "./Calendly";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVisiblePreston: false,
      isVisibleWill: false,
      calendarIconText: false,
      messageIconText: false,
      showCalender: false
    };
  }

  toggleVisibleWill = () =>
    this.setState({ isVisibleWill: !this.state.isVisibleWill });
  toggleVisiblePreston = () =>
    this.setState({ isVisiblePreston: !this.state.isVisiblePreston });
  toggleMessageText = () =>
    this.setState({ messageIconText: !this.state.messageIconText });
  toggleCalendarText = () =>
    this.setState({ calendarIconText: !this.state.calendarIconText });

  handleClose = () => this.setState({ showCalender: false });
  handleShow = () => this.setState({ showCalender: true });

  // componentDidMount() {
  //   const head = document.querySelector('head');
  //   this.script = document.createElement('script');
  //   this.script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
  //   head.appendChild(this.script);
  // }

  // componentWillUnmount() {
  //   // cleanup the widgets code
  //   const head = document.querySelector('head');
  //   head.removeChild(this.script);
  // }

  render() {
    const windowAspect = window.innerWidth / window.innerHeight;
    const marginTop = windowAspect > 1 ? 140 : 110;
    const marginX = windowAspect > 1 ? 100 : 10;
    const imageSize = windowAspect > 1 ? 150 : 130;

    const prestonVariants = {
      open: {
        x: 0,
        opacity: 1,

        transition: { type: "spring", damping: 14 }
      },
      closed: {
        x: 1500,
        opacity: 0
      }
    };
    const willVariants = {
      open: {
        x: 0,
        opacity: 1,
        transition: { type: "spring", damping: 11 }
      },
      closed: {
        x: -1500,
        opacity: 0
      }
    };

    const connectVariants = {
      open: {
        x: 0,
        opacity: 1,
        transition: { duration: 2 }
      },
      closed: {
        x: 0,
        opacity: 0
      }
    };

    return (
      <Fragment>
        <Container
          style={{
            marginTop: marginTop,
            display: "flex",
            flexDirection: "column"
          }}
          fluid
        >
          <Row className="justify-content-center mb-5">
            <motion.div variants={willVariants} initial="closed" animate="open">
              <Figure
                style={{
                  borderRadius: 10,
                  height: 100,
                  width: 150,
                  marginLeft: marginX,
                  marginRight: marginX
                }}
              >
                <Row className="justify-content-center mb-3">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    onHoverStart={this.toggleVisibleWill}
                    onHoverEnd={this.toggleVisibleWill}
                  >
                    <Button
                      href={`mailto:william@appagetech.com`}
                      target="_blank"
                      variant="link"
                    >
                      <Image
                        src="images/Will_SlackProfilePic.jpg"
                        roundedCircle
                        width={imageSize}
                        height={imageSize}
                        style={{ border: "6px" }}
                      />
                    </Button>
                  </motion.div>
                </Row>
                <Row className="justify-content-center">
                  <h5>William Griffin</h5>
                </Row>
                <Row className="justify-content-center mb-3">
                  <i>Los Angeles</i>
                </Row>
              </Figure>
            </motion.div>

            <motion.div
              variants={prestonVariants}
              initial="closed"
              animate="open"
            >
              <Figure
                style={{
                  borderRadius: 10,
                  height: 100,
                  width: 150,
                  marginLeft: marginX,
                  marginRight: marginX
                }}
              >
                <Row className="justify-content-center mb-3">
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    whileTap={{ scale: 0.9 }}
                    onHoverStart={this.toggleVisiblePreston}
                    onHoverEnd={this.toggleVisiblePreston}
                  >
                    <Button
                      href={`mailto:preston@appagetech.com`}
                      target="_blank"
                      variant={"link"}
                    >
                      <Image
                        src="images/Preston_BW_profilePic2.jpg"
                        roundedCircle
                        width={imageSize}
                        height={imageSize}
                        style={{ border: "6px" }}
                      />
                    </Button>
                  </motion.div>
                </Row>
                <Row className="justify-content-center">
                  <h5>Preston Chaplin</h5>
                </Row>
                <Row className="justify-content-center mb-3">
                  <i>New York</i>
                </Row>
              </Figure>
            </motion.div>
          </Row>

          <motion.div
            variants={connectVariants}
            initial="closed"
            animate="open"
          >
            <Row style={{ justifyContent: "center", marginBottom: 10 }}>
              <h6>
                <i>Let's connect!</i>
              </h6>
            </Row>

            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                <Col>
                  <Row style={{ justifyContent: "center", marginBottom: 12 }}>
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      size="2x"
                      style={{ background: "#fff", color: "#000" }}
                      onClick={this.handleShow}
                      />
                      <Modal show={this.state.showCalender} onHide={this.handleClose}>
                        <Modal.Header closeButton><Modal.Title>Schedule a Meeting</Modal.Title></Modal.Header>
                        <Modal.Body>
                          <Calendly />
                        </Modal.Body>
                      </Modal>
                  </Row>
                  <Row style={{ justifyContent: "center", marginTop: 17 }}>
                    <p style={{ marginLeft: 10, marginRight: 10 }}>
                      schedule a meeting
                    </p>
                  </Row>
                </Col>
              </motion.div>

              <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
                <Col>
                  <Row style={{ justifyContent: "center", marginBottom: 1 }}>
                    <ContactForm
                      toggleLockNavigation={this.props.toggleLockNavigation}
                    />
                  </Row>
                  <Row style={{ justifyContent: "center" }}>
                    <p style={{ marginLeft: 10, marginRight: 10 }}>
                      message App Age
                    </p>
                  </Row>
                </Col>
              </motion.div>
            </Row>
          </motion.div>

          <Container
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "black"
            }}
            fluid
          >
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <Button
                href="https://www.linkedin.com/company/app-age-technologies"
                target="_blank"
                variant="link"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  size="3x"
                  style={{ background: "#000", color: "#fff" }}
                />
              </Button>
            </motion.div>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <Button
                href="https://twitter.com/age_app"
                target="_blank"
                variant="link"
              >
                <FontAwesomeIcon
                  icon={faTwitterSquare}
                  size="3x"
                  style={{ background: "#000", color: "#fff" }}
                />
              </Button>
            </motion.div>
            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <Button
                href="https://www.instagram.com/appagetechnologies/"
                target="_blank"
                variant="link"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="3x"
                  style={{ background: "#000", color: "#fff" }}
                />
              </Button>
            </motion.div>

            <motion.div whileHover={{ scale: 1.2 }} whileTap={{ scale: 0.9 }}>
              <Button
                href="https://www.facebook.com/App-Age-Technologies-109527830463591/?modal=admin_todo_tour"
                target="_blank"
                variant="link"
              >
                <FontAwesomeIcon
                  icon={faFacebookSquare}
                  size="3x"
                  style={{ background: "#000", color: "#fff" }}
                />
              </Button>
            </motion.div>
          </Container>
        </Container>
      </Fragment>
    );
  }
}

export default Contact;
