import React, { Fragment } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './App.css';
import About from './About';
import Navigation from './Nav';
import { Projects } from './Projects';
import Contact from './Contact';
import Home from './Home';
import Client from './Client';
import ReactGA from 'react-ga';

const history = createBrowserHistory();
history.listen((location) => {
  ReactGA.pageview(location.pathname);
});

ReactGA.initialize('UA-149946172-2');

function App() {
  return (
    <Fragment>
      <Router history={history}>
        <Route component={Navigation} />
        <Switch>
          <Route
            exact
            path='/'
            render={({ history, location, match }) => <Home history={history} location={location} match={match} />}
          />
          <Route exact path='/about' component={About} />
          <Route exact path='/contact' component={Contact} />
          <Route exact path='/projects' component={Projects} />
          <Route exact path='/client' component={Client} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
