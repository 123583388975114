import React from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { EquirectangularToCubeGenerator } from "three/examples/jsm/loaders/EquirectangularToCubeGenerator.js";
import { PMREMGenerator } from "three/examples/jsm/pmrem/PMREMGenerator.js";
import { PMREMCubeUVPacker } from "three/examples/jsm/pmrem/PMREMCubeUVPacker.js";
import { GPUComputationRenderer } from "three/examples/jsm/misc/GPUComputationRenderer.js";
import { SimplexNoise } from "three/examples/jsm/math/SimplexNoise.js";
import { TWEEN } from "three/examples/jsm/libs/tween.module.min.js";

let camera, glScene, glRenderer, container;
let cubeGenerator, pmremGenerator, pmremCubeUVPacker;
let logo, about, contact, projects, client;
let logoType, aboutType, contactType, projectsType, clientType;
let intersected;
let waterUniforms, waterMesh, meshRay, gpuCompute, heightmapVariable;
let mouseMoved = false;
let mouseCoords = new THREE.Vector2();
let raycaster = new THREE.Raycaster();
let WIDTH = 512;
let BOUNDS = 256;
let placementDirection = "horizontal";
let touchEvent = false;
const windowAspect = window.innerWidth / window.innerHeight;
let loaded = false;

const navbarPlacement = {
  horizontal: {
    about: {
      x: -0.3,
      y: 2,
      z: 0,
      scale: new THREE.Vector3(1, 0.5, 1)
    },
    aboutType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    client: {
      x: -0.3,
      y: 2,
      z: 0,
      scale: new THREE.Vector3(1, 0.5, 1)
    },
    clientType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    projects: {
      x: -0.3,
      y: 2,
      z: 0,
      scale: new THREE.Vector3(1, 0.5, 1)
    },
    projectsType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    contact: {
      x: -0.3,
      y: 2,
      z: 0,
      scale: new THREE.Vector3(1, 0.5, 1)
    },
    contactType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    logo: {
      x: -0.3,
      y: 2,
      z: 0,
      scale: new THREE.Vector3(0.8, 0.8, 0.8)
    },
    logoType: {
      scale: new THREE.Vector3(0.8, 0.8, 0.8)
    }
  },
  // Vertical orientation of screen when CSS3D component is active
  vertical: {
    about: {
      x: 1.3,
      y: 2.71,
      z: 0,

      scale: new THREE.Vector3(1, 0.35, 1)
    },
    aboutType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    client: {
      x: -1.3,

      y: 3.39,
      z: 0,
      scale: new THREE.Vector3(1, 0.35, 1)
    },
    clientType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    projects: {
      x: -1.3,
      y: 2.71,
      z: 0,
      scale: new THREE.Vector3(1, 0.35, 1)
    },
    projectsType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    contact: {
      x: 1.3,

      y: 3.39,
      z: 0,
      scale: new THREE.Vector3(1, 0.35, 1)
    },
    contactType: {
      scale: new THREE.Vector3(1, 1, 1)
    },
    logo: {
      x: 0,
      y: 0.75,
      z: 0,
      scale: new THREE.Vector3(0.85, 0.85, 0.85)
    },
    logoType: {
      scale: new THREE.Vector3(0.85, 0.85, 0.85)
    }
  }
};

const displayIcons = {
  logo: {
    horizontal: { x: -2.2, y: 0 },
    vertical: { x: 0, y: 1.2 }
  },
  about: {
    horizontal: { x: -0.97, y: 0 },
    vertical: { x: -0.5, y: -0.6 }
  },
  contact: {
    horizontal: { x: 0, y: 0 },
    vertical: { x: -0.5, y: -1.6 }
  },
  projects: {
    horizontal: { x: 0.97, y: 0 },
    vertical: { x: 0.5, y: -0.6 }
  },
  client: {
    horizontal: { x: 1.94, y: 0 },
    vertical: { x: 0.5, y: -1.6 }
  }
};

export default function Home(props) {
  let navPosition = "middle";

  const setLoadLocations = () => {
    const windowAspect = window.innerWidth / window.innerHeight;
    if (windowAspect < 1) {
      placementDirection = "vertical";
    }
  };

  const createGlRenderer = () => {
    var glRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    glRenderer.setClearColor(0xecf8ff, 1);
    glRenderer.setPixelRatio(window.devicePixelRatio);
    glRenderer.setSize(window.innerWidth, window.innerHeight);
    glRenderer.domElement.style.position = "absolute";
    glRenderer.domElement.style.top = 0;
    glRenderer.domElement.style.zIndex = 10;
    return glRenderer;
  };

  const initialize = () => {
    camera = new THREE.PerspectiveCamera(
      30,
      window.innerWidth / window.innerHeight,
      0.25,
      4000
    );
    camera.position.set(0, 0, 224);
    camera.lookAt(0, 0, 0);
    glRenderer = createGlRenderer();
    container = document.createElement("div");
    document.body.appendChild(container);
    container.appendChild(glRenderer.domElement);
    glScene = new THREE.Scene();

    window.addEventListener("resize", onWindowResize, false);
    document.addEventListener("mousemove", onDocumentMouseMove, false);
    document.addEventListener("touchstart", onTouchStartScrollable, false);
    document.addEventListener("mousedown", onDocumentMouseDown, false);

    initWater();
  };

  const showThreeJs = () => {
    glRenderer.domElement.style.visibility = "visible";
    document.addEventListener("mousemove", onDocumentMouseMove, false);
    document.addEventListener("touchstart", onTouchStartScrollable, false);
    document.addEventListener("mousedown", onDocumentMouseDown, false);
  };

  const hideThreeJs = () => {
    glRenderer.domElement.style.visibility = "hidden";
    document.removeEventListener("mousemove", onDocumentMouseMove, false);
    document.removeEventListener("touchstart", onTouchStartScrollable, false);
    document.removeEventListener("mousedown", onDocumentMouseDown, false);
  };

  const loadAssets = props => {
    new RGBELoader()
      .setDataType(THREE.UnsignedByteType)
      .setPath("textures/")
      .load("AbstractStudio03.hdr", function(texture) {
        cubeGenerator = new EquirectangularToCubeGenerator(texture, {
          resolution: 512
        });
        cubeGenerator.update(glRenderer);
        pmremGenerator = new PMREMGenerator(cubeGenerator.renderTarget.texture);
        pmremGenerator.update(glRenderer);
        pmremCubeUVPacker = new PMREMCubeUVPacker(pmremGenerator.cubeLods);
        pmremCubeUVPacker.update(glRenderer);
        const hdrEnvMap = pmremCubeUVPacker.CubeUVRenderTarget.texture;

        const emissiveMapLoader = new THREE.TextureLoader();
        const emissiveMap = emissiveMapLoader.load(
          "textures/EmissiveMap_01.png"
        );
        emissiveMap.anisotropy = 16;

        // Models
        const typeParams = {
          envMap: hdrEnvMap,
          envMapIntensity: 1,
          color: 0x040404,
          metalness: 1,
          roughness: 0.2,
          emissive: 0x000000,
          emissiveIntensity: 1
        };
        const iconParams = {
          envMap: hdrEnvMap,
          envMapIntensity: 1,
          emissiveMap: emissiveMap,
          emissiveIntensity: 0.5,
          emissive: 0xb3dde9,
          color: 0x3da3e3,
          metalness: 1,
          roughness: 0.5,
          transparent: true
        };
        const zPos = 215;
        const zRot = null;
        const scale = new THREE.Vector3(1.3, 1.3, 1.3);

        const logoTypeLoader = new GLTFLoader().setPath("/models/");
        logoTypeLoader.load("Logo_Type_30percentZ.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(typeParams);
              child.scale.copy(scale);
              logoType = child;
              logoType.name = "logoType";
            }
          });
          gltf.scene.position.x = displayIcons.logo[placementDirection].x;
          gltf.scene.position.y = displayIcons.logo[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            logoType.position.x = navbarPlacement[placementDirection].logo.x;
            logoType.position.y = navbarPlacement[placementDirection].logo.y;
            logoType.scale.copy(
              navbarPlacement[placementDirection].logoType.scale
            );
          }
        });

        const logoIconLoader = new GLTFLoader().setPath("/models/");
        logoIconLoader.load("Solid_Icon.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(iconParams);
              child.scale.copy(scale);
              logo = child;
              logo.name = "logoIcon";
            }
          });
          gltf.scene.position.x = displayIcons.logo[placementDirection].x;
          gltf.scene.position.y = displayIcons.logo[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            logo.position.x = navbarPlacement[placementDirection].logo.x;
            logo.position.y = navbarPlacement[placementDirection].logo.y;
            logo.scale.copy(navbarPlacement[placementDirection].logo.scale);
          }
        });

        const contactTypeLoader = new GLTFLoader().setPath("/models/");
        contactTypeLoader.load("Contact_Type_30percentZ.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(typeParams);
              contactType = child;
              contactType.name = "contactType";
            }
          });
          glScene.add(gltf.scene);
          gltf.scene.position.x = displayIcons.contact[placementDirection].x;
          gltf.scene.position.y = displayIcons.contact[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          if (navPosition === "top") {
            contactType.position.x =
              navbarPlacement[placementDirection].contact.x;
            contactType.position.y =
              navbarPlacement[placementDirection].contact.y;
            contactType.scale.copy(
              navbarPlacement[placementDirection].contactType.scale
            );
          }
        });

        const contactIconLoader = new GLTFLoader().setPath("/models/");
        contactIconLoader.load("Solid_Icon.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(iconParams);
              contact = child;
              contact.name = "contactIcon";
            }
          });
          gltf.scene.position.x = displayIcons.contact[placementDirection].x;
          gltf.scene.position.y = displayIcons.contact[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            contact.position.x = navbarPlacement[placementDirection].contact.x;
            contact.position.y = navbarPlacement[placementDirection].contact.y;
            contact.scale.copy(
              navbarPlacement[placementDirection].contact.scale
            );
            contact.material.opacity = 0;
          }
          contact.callback = () => {
            if (props.match.url === "/") {
              hideThreeJs();
              props.history.push("/contact");
            }
          };
        });

        const aboutTypeLoader = new GLTFLoader().setPath("/models/");
        aboutTypeLoader.load("About_Type_30percentZ.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(typeParams);
              aboutType = child;
              aboutType.name = "aboutType";
            }
          });
          gltf.scene.position.x = displayIcons.about[placementDirection].x;
          gltf.scene.position.y = displayIcons.about[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            aboutType.position.x = navbarPlacement[placementDirection].about.x;
            aboutType.position.y = navbarPlacement[placementDirection].about.y;
            aboutType.scale.copy(
              navbarPlacement[placementDirection].aboutType.scale
            );
          }
        });

        const aboutIconLoader = new GLTFLoader().setPath("/models/");
        aboutIconLoader.load("Solid_Icon.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(iconParams);
              about = child;
              about.name = "aboutIcon";
            }
          });
          gltf.scene.position.x = displayIcons.about[placementDirection].x;
          gltf.scene.position.y = displayIcons.about[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            about.position.x = navbarPlacement[placementDirection].about.x;
            about.position.y = navbarPlacement[placementDirection].about.y;
            about.scale.copy(navbarPlacement[placementDirection].about.scale);
            about.material.opacity = 0;
          }
          about.callback = () => {
            if (props.match.url === "/") {
              hideThreeJs();
              props.history.push("/about");
            }
          };
        });

        const projectsTypeLoader = new GLTFLoader().setPath("/models/");
        projectsTypeLoader.load("Projects_Type_30percentZ.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(typeParams);
              projectsType = child;
              projectsType.name = "projectsType";
            }
          });
          gltf.scene.position.x = displayIcons.projects[placementDirection].x;
          gltf.scene.position.y = displayIcons.projects[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            projectsType.position.x =
              navbarPlacement[placementDirection].projects.x;
            projectsType.position.y =
              navbarPlacement[placementDirection].projects.y;
            projectsType.scale.copy(
              navbarPlacement[placementDirection].projectsType.scale
            );
          }
        });

        const projectsIconLoader = new GLTFLoader().setPath("/models/");
        projectsIconLoader.load("Solid_Icon.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(iconParams);
              projects = child;
              projects.name = "projectsIcon";
            }
          });
          gltf.scene.position.x = displayIcons.projects[placementDirection].x;
          gltf.scene.position.y = displayIcons.projects[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            projects.position.x =
              navbarPlacement[placementDirection].projects.x;
            projects.position.y =
              navbarPlacement[placementDirection].projects.y;
            projects.scale.copy(
              navbarPlacement[placementDirection].projects.scale
            );
            projects.material.opacity = 0;
          }
          projects.callback = () => {
            if (props.match.url === "/") {
              hideThreeJs();
              props.history.push("/projects");
            }
          };
        });

        const clientTypeLoader = new GLTFLoader().setPath("/models/");
        clientTypeLoader.load("Client_Type_30PercentZ.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(typeParams);
              clientType = child;
              clientType.name = "clientType";
            }
          });
          gltf.scene.position.x = displayIcons.client[placementDirection].x;
          gltf.scene.position.y = displayIcons.client[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            clientType.position.x =
              navbarPlacement[placementDirection].client.x;
            clientType.position.y =
              navbarPlacement[placementDirection].client.y;
            clientType.scale.copy(
              navbarPlacement[placementDirection].clientType.scale
            );
          }
        });

        const clientIconLoader = new GLTFLoader().setPath("/models/");
        clientIconLoader.load("Solid_Icon.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              child.material = new THREE.MeshStandardMaterial(iconParams);
              client = child;
              client.name = "clientIcon";
            }
          });
          gltf.scene.position.x = displayIcons.client[placementDirection].x;
          gltf.scene.position.y = displayIcons.client[placementDirection].y;
          gltf.scene.position.z = zPos;
          gltf.scene.rotation.z = zRot;
          glScene.add(gltf.scene);
          if (navPosition === "top") {
            client.position.x = navbarPlacement[placementDirection].client.x;
            client.position.y = navbarPlacement[placementDirection].client.y;
            client.scale.copy(navbarPlacement[placementDirection].client.scale);
            client.material.opacity = 0;
          }
          client.callback = () => {
            if (props.match.url === "/") {
              hideThreeJs();
              props.history.push("/client");
            }
          };
        });
        pmremGenerator.dispose();
        pmremCubeUVPacker.dispose();
      });
  };

  const initWater = () => {
    const zPos = 0;
    const materialColor = 0x000000;
    var geometry = new THREE.PlaneBufferGeometry(
      BOUNDS,
      BOUNDS,
      WIDTH - 1,
      WIDTH - 1
    );
    var material = new THREE.ShaderMaterial({
      uniforms: THREE.UniformsUtils.merge([
        THREE.ShaderLib["phong"].uniforms,
        {
          heightmap: { value: null }
        }
      ]),
      vertexShader: document.getElementById("waterVertexShader").textContent,
      fragmentShader: THREE.ShaderChunk["meshphong_frag"]
    });
    material.lights = true;
    material.color = new THREE.Color(materialColor);
    material.specular = new THREE.Color(0x111111);
    material.shininess = 100;
    material.uniforms["diffuse"].value = material.color;
    material.uniforms["specular"].value = material.specular;
    material.uniforms["shininess"].value = Math.max(material.shininess, 1e-4);
    material.uniforms["opacity"].value = material.opacity;
    material.defines.WIDTH = WIDTH.toFixed(1);
    material.defines.BOUNDS = BOUNDS.toFixed(1);
    waterUniforms = material.uniforms;
    waterMesh = new THREE.Mesh(geometry, material);
    waterMesh.matrixAutoUpdate = false;
    waterMesh.rotation.x = -Math.PI / 2;
    waterMesh.position.z = zPos;
    waterMesh.updateMatrix();
    glScene.add(waterMesh);
    var geometryRay = new THREE.PlaneBufferGeometry(BOUNDS, BOUNDS, 1, 1);
    meshRay = new THREE.Mesh(
      geometryRay,
      new THREE.MeshBasicMaterial({ color: 0xffffff, visible: false })
    );
    meshRay.matrixAutoUpdate = false;
    meshRay.updateMatrix();
    glScene.add(meshRay);
    gpuCompute = new GPUComputationRenderer(WIDTH, WIDTH, glRenderer);
    var heightmap0 = gpuCompute.createTexture();
    fillTexture(heightmap0);
    heightmapVariable = gpuCompute.addVariable(
      "heightmap",
      document.getElementById("heightmapFragmentShader").textContent,
      heightmap0
    );
    gpuCompute.setVariableDependencies(heightmapVariable, [heightmapVariable]);
    heightmapVariable.material.uniforms["mousePos"] = {
      value: new THREE.Vector2(10000, 10000)
    };
    heightmapVariable.material.uniforms["mouseSize"] = { value: 4 };
    heightmapVariable.material.uniforms["viscosityConstant"] = {
      value: 0.97
    };
    heightmapVariable.material.uniforms["heightCompensation"] = {
      value: 0
    };
    heightmapVariable.material.defines.BOUNDS = BOUNDS.toFixed(1);
    var error = gpuCompute.init();
    if (error !== null) {
      console.error(error);
    }

    gpuCompute.compute();
    waterUniforms["heightmap"].value = gpuCompute.getCurrentRenderTarget(
      heightmapVariable
    ).texture;
  };

  const fillTexture = texture => {
    var simplex = new SimplexNoise();
    var waterMaxHeight = 1;
    function noise(x, y) {
      var multR = waterMaxHeight;
      var mult = 0.025;
      var r = 0;
      for (var i = 0; i < 15; i++) {
        r += multR * simplex.noise(x * mult, y * mult);
        multR *= 0.53 + 0.025 * i;
        mult *= 1.25;
      }
      return r;
    }
    var pixels = texture.image.data;
    var p = 0;
    for (var j = 0; j < WIDTH; j++) {
      for (var i = 0; i < WIDTH; i++) {
        var x = (i * 128) / WIDTH;
        var y = (j * 128) / WIDTH;
        pixels[p + 0] = noise(x, y);
        pixels[p + 1] = pixels[p + 0];
        pixels[p + 2] = 0;
        pixels[p + 3] = 1;
        p += 4;
      }
    }
  };

  const update = () => {
    requestAnimationFrame(update);

    glRenderer.render(glScene, camera);

    if (windowAspect > 1) {
      camera.position.x += (mouseCoords.x - camera.position.x) * 0.05;
      camera.lookAt(glScene.position);
    }

    raycaster.setFromCamera(mouseCoords, camera);
    if (mouseMoved && logo && about && contact && projects && client) {
      var uniforms = heightmapVariable.material.uniforms;
      var intersectWater = raycaster.intersectObject(meshRay);

      // raycast water
      if (intersectWater.length > 0) {
        // ** turn off emissive type
        glScene.traverse(function(child) {
          if (
            (child.isMesh && child.name === "logoType") ||
            (child.isMesh && child.name === "clientType") ||
            (child.isMesh && child.name === "projectsType") ||
            (child.isMesh && child.name === "contactType") ||
            (child.isMesh && child.name === "aboutType")
          ) {
            child.material.emissive.setHex(0x000000);
          }
        });
        var point = intersectWater[0].point;
        uniforms["mousePos"].value.set(point.x, point.z);
      } else {
        uniforms["mousePos"].value.set(10000, 10000);
      }
      mouseMoved = false;

      // raycast buttons
      const intersectButtons = raycaster.intersectObjects([
        logo,
        about,
        contact,
        projects,
        client
      ]);

      //3D
      if (intersectButtons.length > 0) {
        intersected = intersectButtons[0].object;
        // ** clientIcon

        if (intersected.isMesh && intersected.name === "clientIcon") {
          glScene.traverse(function(child) {
            if (child.isMesh && child.name === "clientType") {
              child.material.emissive.setHex(0xff0042); //teal
            } else if (
              (child.isMesh && child.name === "projectsType") ||
              (child.isMesh && child.name === "contactType") ||
              (child.isMesh && child.name === "aboutType")
            ) {
              child.material.emissive.setHex(0x000000);
            }
          });
        }

        // ** projectsIcon

        if (intersected.isMesh && intersected.name === "projectsIcon") {
          glScene.traverse(function(child) {
            if (child.isMesh && child.name === "projectsType") {
              child.material.emissive.setHex(0xff0042);
            } else if (
              (child.isMesh && child.name === "clientType") ||
              (child.isMesh && child.name === "contactType") ||
              (child.isMesh && child.name === "aboutType")
            ) {
              child.material.emissive.setHex(0x000000);
            }
          });
        }

        // ** contactIcon

        if (intersected.isMesh && intersected.name === "contactIcon") {
          glScene.traverse(function(child) {
            if (child.isMesh && child.name === "contactType") {
              child.material.emissive.setHex(0xff0042);
            } else if (
              (child.isMesh && child.name === "clientType") ||
              (child.isMesh && child.name === "projectsType") ||
              (child.isMesh && child.name === "aboutType")
            ) {
              child.material.emissive.setHex(0x000000);
            }
          });
        }

        // ** aboutIcon

        if (intersected.isMesh && intersected.name === "aboutIcon") {
          glScene.traverse(function(child) {
            if (child.isMesh && child.name === "aboutType") {
              child.material.emissive.setHex(0xff0042);
            } else if (
              (child.isMesh && child.name === "clientType") ||
              (child.isMesh && child.name === "projectsType") ||
              (child.isMesh && child.name === "contactType")
            ) {
              child.material.emissive.setHex(0x000000);
            }
          });
        }
      } else {
        intersected = null;
      }
    }
    // Checks the if update function needs to move the navbar based on click and component statefulness
    TWEEN.update();
    waterUniforms["heightmap"].value = gpuCompute.getCurrentRenderTarget(
      heightmapVariable
    ).texture;
    gpuCompute.compute();
  };

  const onDocumentMouseDown = event => {
    // Does not use event.preventDefault(), manually handles touch events
    if (touchEvent === false) {
      if (logo && about && contact && projects && client) {
        setMouseCoords(event.clientX, event.clientY);
        raycaster.setFromCamera(mouseCoords, camera);
        const intersectButtonsMd = raycaster.intersectObjects([
          logo,
          about,
          contact,
          projects,
          client
        ]);
        if (intersectButtonsMd.length > 0) {
          camera.position.x = 0;
          if (intersectButtonsMd[0].object.callback) {
            camera.position.x = 0;
            intersectButtonsMd[0].object.callback();
          }
        }
      }
    }
    touchEvent = false;
  };

  const setMouseCoords = (x, y) => {
    mouseCoords.set(
      (x / glRenderer.domElement.clientWidth) * 2 - 1,
      -(y / glRenderer.domElement.clientHeight) * 2 + 1
    );
    mouseMoved = true;
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    glRenderer.setSize(window.innerWidth, window.innerHeight);
  };

  const onDocumentMouseMove = event => {
    event.preventDefault();
    setMouseCoords(event.clientX, event.clientY);
  };

  const onTouchStartScrollable = event => {
    if (event.touches.length === 1) {
      // Assumes that click and mouse down will both be called by the browser
      setMouseCoords(event.touches[0].pageX, event.touches[0].pageY);

      if (logo && about && contact && projects && client) {
        raycaster.setFromCamera(mouseCoords, camera);
        const intersectButtonsMd = raycaster.intersectObjects([
          logo,
          about,
          contact,
          projects,
          client
        ]);
        if (intersectButtonsMd.length > 0) {
          if (intersectButtonsMd[0].object.callback) {
            camera.position.x = 0;
            mouseCoords.set(0, 0);
            intersectButtonsMd[0].object.callback();
          }
        }
        touchEvent = true;
      }
    }
  };

  if (loaded === false) {
    setLoadLocations();
    initialize();
    loadAssets(props);
    update();
    loaded = true;
  } else {
    showThreeJs();
  }
  return (
    <div>
      <h1>App Age Technologies</h1>
      <ul>
        <li><a href="#/about">About</a></li>
        <li><a href="#/contact">Contact</a></li>
        <li><a href="#/projects">Projects</a></li>
      </ul>
    </div>
  );
}

export { glRenderer };
