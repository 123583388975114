import React, { Component, Fragment } from 'react';
import SplitText from 'react-pose-text';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { motion } from 'framer-motion';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = { isVisible: false, isOpen: false };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        isVisible: !this.state.isVisible,
      });
    }, 100);
    setTimeout(this.toggle, 2800);
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  figureSlices = [
    'images/Figure-Animation/Figure-Black-Background.png',
    'images/Figure-Animation/Figure-01.png',
    'images/Figure-Animation/Figure-02.png',
    'images/Figure-Animation/Figure-03.png',
    'images/Figure-Animation/Figure-04.png',
    'images/Figure-Animation/Figure-05.png',
    'images/Figure-Animation/Lines.png',
    'images/Figure-Animation/AppAge.png',
  ];

  render() {
    const windowAspect = window.innerWidth / window.innerHeight;
    const marginTop = windowAspect > 1 ? 120 : 110;
    const textSize = windowAspect > 1 ? 20 : 16;
    const lineSpace = windowAspect > 1 ? 2 : 1.8;
    const paddingX = windowAspect > 1 ? 100 : 40;
    const strongTextSize = windowAspect > 1 ? 30 : 22;

    const { isVisible } = this.state;

    const animateText = {
      exit: { opacity: 0, y: 20 },
      enter: {
        opacity: 1,
        y: 0,
        delay: ({ wordIndex }) => wordIndex * 100,
      },
    };

    const listVariants = {
      open: {
        transition: { staggerChildren: 0.07, delayChildren: 0.2 },
      },
      closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 },
      },
    };

    const menuItemVariants = {
      open: {
        y: 0,
        opacity: 1,
        transition: {
          y: { stiffness: 1000, velocity: -100 },
        },
      },
      closed: {
        y: 50,
        opacity: 0,
        transition: {
          y: { stiffness: 1000 },
        },
      },
    };

    const figuresListVariants = {
      open: {
        transition: { staggerChildren: 0.25, delayChildren: 0.1 },
      },
      closed: {
        transition: { staggerChildren: 0.01, staggerDirection: -1 },
      },
    };

    const figuresItemVariants = {
      open: {
        x: 0,
        opacity: 1,
      },
      closed: {
        x: 500,
        opacity: 0,
        transition: {
          duration: 0,
        },
      },
    };

    return (
      <Fragment>
        {isVisible && (
          <Container
            style={{
              marginTop: marginTop,
              display: 'flex',
              flexDirection: 'column',
              padding: 0,
            }}
            fluid
          >
            <Image
              src={'images/Figure-Animation/Figure-Black-Background.png'}
              style={{ position: 'relative' }}
              width='100%'
            />

            <Container>
              <motion.ul
                initial='closed'
                animate='open'
                variants={figuresListVariants}
                style={{
                  listStyleType: 'none',
                }}
              >
                {this.figureSlices.map((figureUrl, idx) => (
                  <motion.li variants={figuresItemVariants} style={{ position: 'absolute', left: 0, top: marginTop }}>
                    <Image src={figureUrl} width='100%' height='auto' />
                  </motion.li>
                ))}
              </motion.ul>
            </Container>
            <Row>
              <div
                className='lead'
                style={{
                  fontSize: textSize,
                  lineHeight: lineSpace,
                  textAlign: 'justify',
                  paddingLeft: paddingX,
                  paddingRight: paddingX,
                  marginBottom: 40,
                }}
              >
                <span
                  style={{
                    fontSize: strongTextSize,
                    fontWeight: 700,
                  }}
                >
                  <SplitText initialPose='exit' pose='enter' charPoses={animateText}>
                    App Age Technologies
                  </SplitText>
                </span>
                <p>
                  produces software that informs, entertains, solves problems and enriches lives. Co-founders William
                  Griffin and Preston Chaplin bring unique and impressive professional experiences to this vanguard
                  software development company. Decades of experience in digital imaging and high-profile advertising
                  production provides assurance that your brand will be presented in the best possible light via App Age
                  software. Extensive experience manipulating highly technical data for the financial industry and
                  providing financial consulting for businesses big and small ensures that App Age can tackle complex
                  technical challenges and advise clients of any size on the best paths to success. From microsites to
                  distributed mobile apps, we’re software developers devoted to delivering success in surprising ways.
                </p>
              </div>
            </Row>
            <Row
              style={{
                backgroundColor: 'black',
                color: 'white',
                justifyContent: 'space-around',
                paddingTop: 20,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              <motion.ul
                initial='closed'
                animate='open'
                variants={listVariants}
                style={{
                  listStyleType: 'none',
                }}
              >
                <motion.li variants={menuItemVariants}>
                  <h4>Capabilities</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>Web Development</motion.li>
                <motion.li variants={menuItemVariants}>iOS & Android Development</motion.li>
                <motion.li variants={menuItemVariants}>AI / Computer Vision</motion.li>
                <motion.li variants={menuItemVariants}>Interactive 3D Design</motion.li>
                <motion.li variants={menuItemVariants}>Web Animations</motion.li>
                <motion.li variants={menuItemVariants}>CGI / 3D Modeling</motion.li>
                <motion.li variants={menuItemVariants}>Photo Retouching</motion.li>
                <motion.li variants={menuItemVariants}>Product Design & Launch</motion.li>
                <motion.li variants={menuItemVariants}>Cloud Services</motion.li>
                <motion.li variants={menuItemVariants}>Process Automation</motion.li>
              </motion.ul>

              <motion.ul
                initial='closed'
                animate='open'
                variants={listVariants}
                style={{
                  listStyleType: 'none',
                }}
              >
                <motion.li variants={menuItemVariants}>
                  <h4>Engagement</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>Fixed Price Contract</motion.li>
                <motion.li variants={menuItemVariants}>Hourly Development Work</motion.li>
                <motion.li variants={menuItemVariants}>Equity Based Partnerships</motion.li>
                <motion.li variants={menuItemVariants}>Project Specific Consulting</motion.li>
              </motion.ul>
            </Row>

            <Row
              style={{
                justifyContent: 'space-around',
                paddingTop: 20,
                paddingBottom: 20,
                marginBottom: 10,
                backgroundColor: 'white',
                marginLeft: 0,
                marginRight: 0,
                left: 0,
              }}
            >
              <motion.ul initial='closed' animate='open' variants={listVariants} style={{ listStyleType: 'none' }}>
                <motion.li variants={menuItemVariants}>
                  <h4>Languages</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>JavaScript</motion.li>
                <motion.li variants={menuItemVariants}>Python</motion.li>
                <motion.li variants={menuItemVariants}>HTML/CSS</motion.li>
                <motion.li variants={menuItemVariants}>SQL</motion.li>
                <motion.li variants={menuItemVariants}>MatLab</motion.li>
                <motion.li variants={menuItemVariants}>VBA</motion.li>
              </motion.ul>

              <motion.ul initial='closed' animate='open' variants={listVariants} style={{ listStyleType: 'none' }}>
                <motion.li variants={menuItemVariants}>
                  <h4>Frameworks</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>Node.js</motion.li>
                <motion.li variants={menuItemVariants}>Express.js</motion.li>
                <motion.li variants={menuItemVariants}>React</motion.li>
                <motion.li variants={menuItemVariants}>Sequelize</motion.li>
                <motion.li variants={menuItemVariants}>ThreeJS</motion.li>
                <motion.li variants={menuItemVariants}>OpenCV</motion.li>
              </motion.ul>

              <motion.ul initial='closed' animate='open' variants={listVariants} style={{ listStyleType: 'none' }}>
                <motion.li variants={menuItemVariants}>
                  <h4>Databases</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>MySQL</motion.li>
                <motion.li variants={menuItemVariants}>PostgreSQL</motion.li>
                <motion.li variants={menuItemVariants}>MongoDB</motion.li>
              </motion.ul>

              <motion.ul initial='closed' animate='open' variants={listVariants} style={{ listStyleType: 'none' }}>
                <motion.li variants={menuItemVariants}>
                  <h4>Cloud Services</h4>
                </motion.li>
                <motion.li variants={menuItemVariants}>Azure</motion.li>
                <motion.li variants={menuItemVariants}>Digital Ocean</motion.li>
                <motion.li variants={menuItemVariants}>AWS</motion.li>
                <motion.li variants={menuItemVariants}>Heroku</motion.li>
              </motion.ul>
            </Row>
            <Row style={{ backgroundColor: 'black', height: 100 }}></Row>
          </Container>
        )}
      </Fragment>
    );
  }
}

export default About;
