import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

export default function TodaysIpos(props) {
  const screenShotAnimation = [
    'images/todaysIposAnimation/WebScreenShots-700-Competition.png',
    'images/todaysIposAnimation/WebScreenShots-700-Description.png',
    'images/todaysIposAnimation/WebScreenShots-700-Financials.png',
    'images/todaysIposAnimation/WebScreenShots-700-Proceeds.png',
    'images/todaysIposAnimation/WebScreenShots-700-About.png',
  ];
  const iphoneAnimation = [
    'images/todaysIposAnimation/IphoneTodaysIpos-1.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-2.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-3.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-4.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-5.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-6.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-7.png',
    'images/todaysIposAnimation/IphoneTodaysIpos-8.png',
  ];

  return (
    <Modal {...props} size='xl' centered closeButton>
      <Modal.Header
        style={{
          border: '1px solid #575757',
          backgroundColor: 'black',
          color: 'white',
        }}
        closeButton>
        <Container style={{ paddingLeft: 50 }}>
          <Row style={{ justifyContent: 'space-around', alignItems: 'center' }}>
            <Modal.Title
              as={'h1'}
              style={{
                fontFamily: 'co-headline, sans-serif',
                fontWeight: 700,
                fontStyle: 'normal',
                fontSize: 54,
              }}>
              App Age
            </Modal.Title>

            <Modal.Title
              style={{
                fontFamily: 'ff-kava-web-pro, sans-serif;',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: 24,
                marginTop: 6,
                marginLeft: 20,
                marginRight: 20,
              }}>
              <a
                href='https://www.appagetech.com'
                target='_blank'
                rel='noreferrer noopener'
                style={{
                  textDecoration: 'none',
                  color: 'white',
                }}>
                Today's IPO
              </a>
            </Modal.Title>

            <Modal.Title
              as={'p'}
              style={{
                fontFamily: 'co-text, sans-serif',
                fontWeight: 300,
                fontStyle: 'normal',
                fontSize: 12,
                marginTop: 3,
              }}>
              Launched September 2019
            </Modal.Title>
          </Row>
        </Container>
      </Modal.Header>
      <Modal.Body style={{ border: '1px solid #575757' }}>
        <Container>
          <Row style={{ marginBottom: 15 }}>
            <Col></Col>
            <Col>
              <Carousel pauseOnHover={false} interval={1500} controls={false} indicators={true} fade={true}>
                {iphoneAnimation.map((imgSrc) => (
                  <Carousel.Item key={imgSrc}>
                    <img
                      src={imgSrc}
                      alt=''
                      style={{
                        width: 300,
                      }}
                    />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col></Col>
          </Row>
          <Row style={{ marginBottom: 25 }}>
            <Col></Col>
            <Col xs={12} md={10} lg={8} xl={7}>
              <Carousel
                pauseOnHover={false}
                interval={1500}
                controls={true}
                indicators={true}
                fade={true}
                className='shadow'>
                {screenShotAnimation.map((imgSrc) => (
                  <Carousel.Item key={imgSrc}>
                    <Image src={imgSrc} alt='' fluid />
                  </Carousel.Item>
                ))}
              </Carousel>
            </Col>
            <Col></Col>
          </Row>
          <Row
            style={{
              justifyContent: 'center',
              marginBottom: 12,
            }}>
            <h4>Challenge</h4>
          </Row>
          <Row style={{ justifyContent: 'center', marginBottom: 20 }}>
            <Col xs={12} md={10}>
              <p>Provide a user friendly tool to view and explore in depth financial data of companies going public.</p>
            </Col>
          </Row>

          <Row
            style={{
              justifyContent: 'center',
              marginBottom: 12,
            }}>
            <h4>Solution</h4>
          </Row>
          <Row style={{ marginBottom: 20, justifyContent: 'center' }}>
            <Col xs={12} md={10}>
              <p style={{ textIndent: 40 }}>
                Designed and built a robust fullstack web application. Using a dashboard as our main design component,
                we curated the companies’ financial data to expand with the user at each level of exploration. For data
                magangent, we created a backend service to gather, process, and store initial public offering (IPO)
                data. The frontend and backend services run independently using modern scalability technology to allow
                the application to grow with demand.
              </p>
            </Col>
          </Row>
          <Row style={{ justifyContent: 'center', marginBottom: 12 }}>
            <h4>Features</h4>
          </Row>
          <Row style={{ justifyContent: 'center', marginBottom: 20 }}>
            <ul>
              <li>Dashboard creation</li>
              <li>API connectivity with data management and process scheduling</li>
              <li>Logo design, typography, and color selection</li>
              <li>CI / CD pipelines</li>
              <li>Highly available web app (multi-node, load balancing)</li>
            </ul>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: '1px solid #575757',
          justifyContent: 'center',
          backgroundColor: 'black',
          color: 'white',
        }}>
        <Button variant='outline-secondary' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
