import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader.js";
import { EquirectangularToCubeGenerator } from "three/examples/jsm/loaders/EquirectangularToCubeGenerator.js";
import { PMREMGenerator } from "three/examples/jsm/pmrem/PMREMGenerator.js";
import { PMREMCubeUVPacker } from "three/examples/jsm/pmrem/PMREMCubeUVPacker.js";

const createGlRenderer = () => {
  var glRenderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
  glRenderer.setClearColor(0xecf8ff, 1);
  glRenderer.setPixelRatio(window.devicePixelRatio);
  glRenderer.setSize(window.innerWidth, window.innerHeight);
  glRenderer.domElement.style.position = "absolute";
  glRenderer.domElement.style.top = 0;
  return glRenderer;
};

const landingPageExample = nextScene => {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();
  let touchEvent = false;
  let mouseMoved = false;
  // Scene & Camera
  const camera = new THREE.PerspectiveCamera(
    40,
    window.innerWidth / window.innerHeight,
    0.25,
    20
  );
  camera.position.z = 7.5;

  var renderer = createGlRenderer();
  renderer.domElement.style.top = 0;
  renderer.domElement.style.zIndex = 1000;
  var container = document.createElement("div");
  document.body.appendChild(container);
  container.appendChild(renderer.domElement);

  var scene = new THREE.Scene();

  // Objects
  let cubeGenerator, type, icon;

  // load HDRI then models
  new RGBELoader()
    .setDataType(THREE.UnsignedByteType)
    .setPath("textures/")
    .load("diyHdri_01t.hdr", function(texture) {
      cubeGenerator = new EquirectangularToCubeGenerator(texture, {
        resolution: 256
      });

      cubeGenerator.update(renderer);

      const pmremGenerator = new PMREMGenerator(
        cubeGenerator.renderTarget.texture
      );
      pmremGenerator.update(renderer);
      const pmremCubeUVPacker = new PMREMCubeUVPacker(pmremGenerator.cubeLods);
      pmremCubeUVPacker.update(renderer);

      const envMap = pmremCubeUVPacker.CubeUVRenderTarget.texture;

      const emissiveMapLoader = new THREE.TextureLoader();
      const emissiveMap = emissiveMapLoader.load("textures/EmissiveMap_01.png");

      // Models
      new GLTFLoader()
        .setPath("/models/")
        .load("Landing-Logo-Type.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              type = child;
              type.material = new THREE.MeshStandardMaterial({
                envMap: envMap,
                envMapIntensity: 2,
                color: 0x000000,
                metalness: 1,
                roughness: 0.2
              });
            }
          });
          //type.callback = () => startTransition("Type");
          scene.add(gltf.scene);
        });

      new GLTFLoader()
        .setPath("/models/")
        .load("Logo_Icon_Large.glb", function(gltf) {
          gltf.scene.traverse(function(child) {
            if (child.isMesh) {
              icon = child;
              icon.material = new THREE.MeshStandardMaterial({
                envMap: envMap,
                envMapIntensity: 1,
                emissiveMap: emissiveMap,
                emissiveIntensity: 0.5,
                emissive: 0xb3dde9,
                color: 0x3da3e3,
                metalness: 1,
                roughness: 0
              });
            }
          });
          //icon.callback = () => startTransition("Icon");
          scene.add(gltf.scene);
        });
      pmremGenerator.dispose();
      pmremCubeUVPacker.dispose();

      scene.background = new THREE.Color(0x000000);
    });

  const removeLandingMouseDown = () => {
    window.removeEventListener("resize", onWindowResize, false);
    document.removeEventListener("mousedown", onDocumentMouseDown, false);
    document.removeEventListener("touchstart", onDocumentTouchStart, false);
    document.removeEventListener("mousemove", onDocumentMouseMove, false);
  };

  const addLandingMouseDown = () => {
    window.addEventListener("resize", onWindowResize, false);
    document.addEventListener("mousedown", onDocumentMouseDown, false);
    document.addEventListener("touchstart", onDocumentTouchStart, false);
    document.addEventListener("mousemove", onDocumentMouseMove, false);
  };

  const setMouseCoords = (x, y) => {
    mouse.set(
      (x / renderer.domElement.clientWidth) * 2 - 1,
      -(y / renderer.domElement.clientHeight) * 2 + 1
    );
    mouseMoved = true;
  };

  const onDocumentMouseMove = event => {
    event.preventDefault();
    setMouseCoords(event.clientX, event.clientY);
  };

  const destroyRenderer = () => {
    document.body.removeChild(container);
    container.removeChild(renderer.domElement);
    removeLandingMouseDown();
    renderer = 0;
    container = 0;
    scene = 0;
  };

  const onWindowResize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };
  const onDocumentMouseDown = event => {
    // Does not use event.preventDefault(), manually handles touch events
    if (touchEvent === false) {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
      destroyRenderer();
      nextScene(true);
    }
    touchEvent = false;
  };
  const onDocumentTouchStart = event => {
    if (event.touches.length === 1) {
      // Assumes that click and mouse down will both be called by the browser
      mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);

      destroyRenderer();
      touchEvent = true;
      nextScene(true);
    }
  };
  // adds listeners
  addLandingMouseDown();

  const starForge = () => {
    var starQty = 800000;
    let starGeometry = new THREE.SphereGeometry(170, 10, 10);

    const materialOptions = {
      size: 0.06,
      opacity: 0.7
    };

    const starStuff = new THREE.PointCloudMaterial(materialOptions);

    for (var i = 0; i < starQty; i++) {
      var starVertex = new THREE.Vector3();
      starVertex.x = Math.random() * 200 - 100;
      starVertex.y = Math.random() * 200 - 100;
      starVertex.z = Math.random() * 100 - 105;

      starGeometry.vertices.push(starVertex);
    }

    const stars = new THREE.PointCloud(starGeometry, starStuff);
    scene.add(stars);
  };

  starForge();

  const update = () => {
    requestAnimationFrame(update);
    if (icon && type) {
      icon.rotation.y += 0.01;
      type.rotation.y += 0.01;
      camera.position.x += (mouse.x - camera.position.x) * 0.05;
      camera.position.y += (-mouse.y - camera.position.y) * 0.05;
      camera.lookAt(icon.position);
    }
    if (renderer === 0) {
      return;
    }
    renderer.render(scene, camera);
  };

  update();
};

export { landingPageExample };
