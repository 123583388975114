import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import ResponsiveEmbed from "react-bootstrap/ResponsiveEmbed";

export default function Interactive3D(props) {
  return (
    <Modal {...props} size="xl" centered closeButton>
      <Modal.Header
        style={{
          border: "1px solid #575757",
          backgroundColor: "black",
          color: "white"
        }}
        closeButton
      >
        <Container style={{ padding: 50 }}>
          <Row style={{ justifyContent: "space-around", alignItems: "center" }}>
            <Row>
              <Modal.Title
                as={"h1"}
                style={{
                  fontFamily: "co-headline, sans-serif",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontSize: 54
                }}
              >
                App Age
              </Modal.Title>
            </Row>
            <Modal.Title
              style={{
                fontFamily: "ff-kava-web-pro, sans-serif;",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: 24,
                marginTop: 6,
                marginLeft: 20,
                marginRight: 20
              }}
            >
              <a
                href="https://www.appagetech.com"
                target="_blank"
                rel="noreferrer noopener"
                style={{
                  textDecoration: "none",
                  color: "white"
                }}
              >
                Interactive 3D
              </a>
            </Modal.Title>

            <Modal.Title
              as={"p"}
              style={{
                fontFamily: "co-text, sans-serif",
                fontWeight: 300,
                fontStyle: "normal",
                fontSize: 12,
                marginTop: 3
              }}
            >
              Launched September 2019
            </Modal.Title>
          </Row>
        </Container>
      </Modal.Header>

      <Modal.Body style={{ border: "1px solid #575757" }}>
        <Container
          style={{
            fontSize: 16,
            padding: 0,
            margin: 0,
            justifyContent: "center"
          }}
        >
          <Col>
            <Row className="justify-content-center">
              <div style={{ width: 660, height: "auto" }}>
                <ResponsiveEmbed aspectRatio="4by3">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                    type="image/svg+xml"
                    src="/IconSpin02-540.mp4"
                  />
                </ResponsiveEmbed>
              </div>
            </Row>

            <Row
              // className="justify-content-center mb-5"
              style={{ justifyContent: "center", marginBottom: 25 }}
            >
              * The animation above is a video representation of the interactive
              3D scene.
            </Row>

            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Challenge</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <p>
                Incorporate interactive 3D scene into reponsive web app and
                mobile app.
              </p>
            </Row>

            <Row
              style={{
                justifyContent: "center",
                marginBottom: 12
              }}
            >
              <h4>Solution</h4>
            </Row>
            <Row style={{ marginBottom: 20, justifyContent: "center" }}>
              <Col xs={12} md={10}>
                <p style={{ textIndent: 40 }}>
                  Created 3D model of logo text and icon with professional CGI
                  apllications. Designed and created custom 3D light map in
                  external software to simulate physical light and reflections.
                  Imported 3D elements into webGl generated space environment
                  where we animated the models and employed interactive methods
                  to listen for mouse events for web and touch events for
                  mobile. The animated scene runs via webGl making it incredibly
                  fast and smooth.
                </p>
              </Col>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 12 }}>
              <h4>Features</h4>
            </Row>
            <Row style={{ justifyContent: "center", marginBottom: 20 }}>
              <ul>
                <li>Interactive 3D animations</li>
                <li>3D Modeling</li>
                <li>3D Lighting</li>
                <li>
                  utilizes webGl for extremely fast scene rendering on-the-fly
                </li>
                <li>ability to incorporate into any app or website</li>
              </ul>
            </Row>
          </Col>
        </Container>
      </Modal.Body>
      <Modal.Footer
        style={{
          border: "1px solid #575757",
          justifyContent: "center",
          backgroundColor: "black",
          color: "white"
        }}
      >
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
